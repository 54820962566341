@import "colors";

.entity {
  text-align: center;
  position: relative;

  &[data-state="unavailable"] {
    opacity: 0.5;
  }

  &:not(.no-ripple):not([data-state="unavailable"]) {
    transition: background-color 0.25s;

    &:active {
      background-color: $color-grey-700;
      transition: background-color 0s;
    }
  }

  .state,
  .content,
  .title {
    flex-grow: 1;
    z-index: 10;
    display: flex;
    flex-direction: column;
    color: $color-grey-300;
    align-items: center;
    justify-content: space-around;
    transition: all 0.15s linear 0s;
  }

  .state {
    flex-grow: 0.8;
    align-self: stretch;
    align-items: flex-start;
    padding: 0 10%;
    transition: opacity 0.25s;

    span {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .content {
    flex-grow: 3;

    .mdi {
      width: 60%;
    }
  }

  .title {
    span {
      margin-top: -5px;
    }
  }

  &.light,
  &.servo {
    background-repeat: no-repeat;
    background-position: bottom;
    background-image: linear-gradient(
      to bottom,
      $color-grey-700,
      $color-grey-700
    );

    .brightness {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: $color-grey-300;
      background-color: $color-grey-800;
      transition: background-color 0.25s;

      .live {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        z-index: 5;
        font-size: 24px;
        transform: translate(-50%, -50%);
        transition: opacity 0.25s;
      }
    }

    &:not([data-state="unavailable"]):not(.on-pan) {
      .brightness {
        transition: background-color 0.25s;
      }

      &:active {
        .brightness {
          background-color: $color-grey-700;
          transition: background-color 0s;
        }
      }
    }

    &.on-pan {
      .brightness {
        background-color: transparent;
        transition: background-color 0.15s;

        .live {
          opacity: 1;
        }
      }

      .state,
      .content,
      .title {
        opacity: 0;
      }
    }
  }

  &.temperature {
    .state {
      span {
        direction: ltr;
        margin-top: 8px;

        span.c {
          font-size: 12px;
          margin-left: 2px;
          vertical-align: super;
        }
      }
    }
  }

  &.gas {
    .state {
      span {
        direction: ltr;
        margin-top: 8px;

        span.ppm {
          font-size: 12px;
          margin-left: 2px;
        }
      }
    }
  }

  &.soil-moisture {
    .content {
      .mdiSoil {
        height: 70%;
        fill: $color-grey-300;
        transition: all 0.15s linear 0s;
      }
    }

    &:not([data-state="on"]) {
      .mdiSoil {
        fill: $color-grey-500;
      }
    }
  }

  &.servo {
    &[data-state="on"] {
      &:not(.on-pan) .state {
        opacity: 1;
      }

      .title,
      .content {
        color: $color-grey-300;
      }
    }

    .mdi {
      width: 40%;
    }
  }

  &.motion[data-state="on"] .content .mdi {
    color: $color-yellow-700;
    animation: motion 8s linear 0.5s infinite;
  }

  &:not([data-state="on"]) {
    .state {
      opacity: 0;
    }

    .content,
    .title {
      color: $color-grey-500;
    }
  }
}

@keyframes motion {
  2%,
  4%,
  6% {
    transform: translateX(4px);
  }

  1%,
  3%,
  5% {
    transform: translateX(-4px);
  }

  0%,
  7% {
    transform: translateX(0px);
  }
}
