@import "./IRANSans.scss";
@import "./AntDesignModifier.scss";
@import "colors";

:root {
  direction: rtl;
}

body {
  font-family: IRANSansFaNum;
  background-color: $color-grey-900;
  
  -webkit-tap-highlight-color: transparent;
  
  /* Disable text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
