@keyframes push {
  from {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

@keyframes pull {
  to {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
