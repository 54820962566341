// Google’s Material Design palette from
// https://material.google.com/style/color.html#color-color-palette

$color-red-50:           #FFEBEE;
$color-red-100:          #FFCDD2;
$color-red-200:          #EF9A9A;
$color-red-300:          #E57373;
$color-red-400:          #EF5350;
$color-red-500:          #F44336;
$color-red-600:          #E53935;
$color-red-700:          #D32F2F;
$color-red-800:          #C62828;
$color-red-900:          #B71C1C;
$color-red-A100:         #FF8A80;
$color-red-A200:         #FF5252;
$color-red-A400:         #FF1744;
$color-red-A700:         #D50000;

$color-pink-50:          #FCE4EC;
$color-pink-100:         #F8BBD0;
$color-pink-200:         #F48FB1;
$color-pink-300:         #F06292;
$color-pink-400:         #EC407A;
$color-pink-500:         #E91E63;
$color-pink-600:         #D81B60;
$color-pink-700:         #C2185B;
$color-pink-800:         #AD1457;
$color-pink-900:         #880E4F;
$color-pink-A100:        #FF80AB;
$color-pink-A200:        #FF4081;
$color-pink-A400:        #F50057;
$color-pink-A700:        #C51162;

$color-purple-50:        #F3E5F5;
$color-purple-100:       #E1BEE7;
$color-purple-200:       #CE93D8;
$color-purple-300:       #BA68C8;
$color-purple-400:       #AB47BC;
$color-purple-500:       #9C27B0;
$color-purple-600:       #8E24AA;
$color-purple-700:       #7B1FA2;
$color-purple-800:       #6A1B9A;
$color-purple-900:       #4A148C;
$color-purple-A100:      #EA80FC;
$color-purple-A200:      #E040FB;
$color-purple-A400:      #D500F9;
$color-purple-A700:      #AA00FF;

$color-deep-purple-50:   #EDE7F6;
$color-deep-purple-100:  #D1C4E9;
$color-deep-purple-200:  #B39DDB;
$color-deep-purple-300:  #9575CD;
$color-deep-purple-400:  #7E57C2;
$color-deep-purple-500:  #673AB7;
$color-deep-purple-600:  #5E35B1;
$color-deep-purple-700:  #512DA8;
$color-deep-purple-800:  #4527A0;
$color-deep-purple-900:  #311B92;
$color-deep-purple-A100: #B388FF;
$color-deep-purple-A200: #7C4DFF;
$color-deep-purple-A400: #651FFF;
$color-deep-purple-A700: #6200EA;

$color-indigo-50:        #E8EAF6;
$color-indigo-100:       #C5CAE9;
$color-indigo-200:       #9FA8DA;
$color-indigo-300:       #7986CB;
$color-indigo-400:       #5C6BC0;
$color-indigo-500:       #3F51B5;
$color-indigo-600:       #3949AB;
$color-indigo-700:       #303F9F;
$color-indigo-800:       #283593;
$color-indigo-900:       #1A237E;
$color-indigo-A100:      #8C9EFF;
$color-indigo-A200:      #536DFE;
$color-indigo-A400:      #3D5AFE;
$color-indigo-A700:      #304FFE;

$color-blue-50:          #E3F2FD;
$color-blue-100:         #BBDEFB;
$color-blue-200:         #90CAF9;
$color-blue-300:         #64B5F6;
$color-blue-400:         #42A5F5;
$color-blue-500:         #2196F3;
$color-blue-600:         #1E88E5;
$color-blue-700:         #1976D2;
$color-blue-800:         #1565C0;
$color-blue-900:         #0D47A1;
$color-blue-A100:        #82B1FF;
$color-blue-A200:        #448AFF;
$color-blue-A400:        #2979FF;
$color-blue-A700:        #2962FF;

$color-light-blue-50:    #E1F5FE;
$color-light-blue-100:   #B3E5FC;
$color-light-blue-200:   #81D4FA;
$color-light-blue-300:   #4FC3F7;
$color-light-blue-400:   #29B6F6;
$color-light-blue-500:   #03A9F4;
$color-light-blue-600:   #039BE5;
$color-light-blue-700:   #0288D1;
$color-light-blue-800:   #0277BD;
$color-light-blue-900:   #01579B;
$color-light-blue-A100:  #80D8FF;
$color-light-blue-A200:  #40C4FF;
$color-light-blue-A400:  #00B0FF;
$color-light-blue-A700:  #0091EA;

$color-cyan-50:          #E0F7FA;
$color-cyan-100:         #B2EBF2;
$color-cyan-200:         #80DEEA;
$color-cyan-300:         #4DD0E1;
$color-cyan-400:         #26C6DA;
$color-cyan-500:         #00BCD4;
$color-cyan-600:         #00ACC1;
$color-cyan-700:         #0097A7;
$color-cyan-800:         #00838F;
$color-cyan-900:         #006064;
$color-cyan-A100:        #84FFFF;
$color-cyan-A200:        #18FFFF;
$color-cyan-A400:        #00E5FF;
$color-cyan-A700:        #00B8D4;

$color-teal-50:          #E0F2F1;
$color-teal-100:         #B2DFDB;
$color-teal-200:         #80CBC4;
$color-teal-300:         #4DB6AC;
$color-teal-400:         #26A69A;
$color-teal-500:         #009688;
$color-teal-600:         #00897B;
$color-teal-700:         #00796B;
$color-teal-800:         #00695C;
$color-teal-900:         #004D40;
$color-teal-A100:        #A7FFEB;
$color-teal-A200:        #64FFDA;
$color-teal-A400:        #1DE9B6;
$color-teal-A700:        #00BFA5;

$color-green-50:         #E8F5E9;
$color-green-100:        #C8E6C9;
$color-green-200:        #A5D6A7;
$color-green-300:        #81C784;
$color-green-400:        #66BB6A;
$color-green-500:        #4CAF50;
$color-green-600:        #43A047;
$color-green-700:        #388E3C;
$color-green-800:        #2E7D32;
$color-green-900:        #1B5E20;
$color-green-A100:       #B9F6CA;
$color-green-A200:       #69F0AE;
$color-green-A400:       #00E676;
$color-green-A700:       #00C853;

$color-light-green-50:   #F1F8E9;
$color-light-green-100:  #DCEDC8;
$color-light-green-200:  #C5E1A5;
$color-light-green-300:  #AED581;
$color-light-green-400:  #9CCC65;
$color-light-green-500:  #8BC34A;
$color-light-green-600:  #7CB342;
$color-light-green-700:  #689F38;
$color-light-green-800:  #558B2F;
$color-light-green-900:  #33691E;
$color-light-green-A100: #CCFF90;
$color-light-green-A200: #B2FF59;
$color-light-green-A400: #76FF03;
$color-light-green-A700: #64DD17;

$color-lime-50:          #F9FBE7;
$color-lime-100:         #F0F4C3;
$color-lime-200:         #E6EE9C;
$color-lime-300:         #DCE775;
$color-lime-400:         #D4E157;
$color-lime-500:         #CDDC39;
$color-lime-600:         #C0CA33;
$color-lime-700:         #AFB42B;
$color-lime-800:         #9E9D24;
$color-lime-900:         #827717;
$color-lime-A100:        #F4FF81;
$color-lime-A200:        #EEFF41;
$color-lime-A400:        #C6FF00;
$color-lime-A700:        #AEEA00;

$color-yellow-50:        #FFFDE7;
$color-yellow-100:       #FFF9C4;
$color-yellow-200:       #FFF59D;
$color-yellow-300:       #FFF176;
$color-yellow-400:       #FFEE58;
$color-yellow-500:       #FFEB3B;
$color-yellow-600:       #FDD835;
$color-yellow-700:       #FBC02D;
$color-yellow-800:       #F9A825;
$color-yellow-900:       #F57F17;
$color-yellow-A100:      #FFFF8D;
$color-yellow-A200:      #FFFF00;
$color-yellow-A400:      #FFEA00;
$color-yellow-A700:      #FFD600;

$color-amber-50:         #FFF8E1;
$color-amber-100:        #FFECB3;
$color-amber-200:        #FFE082;
$color-amber-300:        #FFD54F;
$color-amber-400:        #FFCA28;
$color-amber-500:        #FFC107;
$color-amber-600:        #FFB300;
$color-amber-700:        #FFA000;
$color-amber-800:        #FF8F00;
$color-amber-900:        #FF6F00;
$color-amber-A100:       #FFE57F;
$color-amber-A200:       #FFD740;
$color-amber-A400:       #FFC400;
$color-amber-A700:       #FFAB00;

$color-orange-50:        #FFF3E0;
$color-orange-100:       #FFE0B2;
$color-orange-200:       #FFCC80;
$color-orange-300:       #FFB74D;
$color-orange-400:       #FFA726;
$color-orange-500:       #FF9800;
$color-orange-600:       #FB8C00;
$color-orange-700:       #F57C00;
$color-orange-800:       #EF6C00;
$color-orange-900:       #E65100;
$color-orange-A100:      #FFD180;
$color-orange-A200:      #FFAB40;
$color-orange-A400:      #FF9100;
$color-orange-A700:      #FF6D00;

$color-deep-orange-50:   #FBE9E7;
$color-deep-orange-100:  #FFCCBC;
$color-deep-orange-200:  #FFAB91;
$color-deep-orange-300:  #FF8A65;
$color-deep-orange-400:  #FF7043;
$color-deep-orange-500:  #FF5722;
$color-deep-orange-600:  #F4511E;
$color-deep-orange-700:  #E64A19;
$color-deep-orange-800:  #D84315;
$color-deep-orange-900:  #BF360C;
$color-deep-orange-A100: #FF9E80;
$color-deep-orange-A200: #FF6E40;
$color-deep-orange-A400: #FF3D00;
$color-deep-orange-A700: #DD2C00;

$color-brown-50:         #EFEBE9;
$color-brown-100:        #D7CCC8;
$color-brown-200:        #BCAAA4;
$color-brown-300:        #A1887F;
$color-brown-400:        #8D6E63;
$color-brown-500:        #795548;
$color-brown-600:        #6D4C41;
$color-brown-700:        #5D4037;
$color-brown-800:        #4E342E;
$color-brown-900:        #3E2723;

$color-grey-50:          #FAFAFA;
$color-grey-100:         #F5F5F5;
$color-grey-200:         #EEEEEE;
$color-grey-300:         #E0E0E0;
$color-grey-400:         #BDBDBD;
$color-grey-500:         #9E9E9E;
$color-grey-600:         #757575;
$color-grey-700:         #616161;
$color-grey-800:         #424242;
$color-grey-900:         #212121;

$color-blue-grey-50:     #ECEFF1;
$color-blue-grey-100:    #CFD8DC;
$color-blue-grey-200:    #B0BEC5;
$color-blue-grey-300:    #90A4AE;
$color-blue-grey-400:    #78909C;
$color-blue-grey-500:    #607D8B;
$color-blue-grey-600:    #546E7A;
$color-blue-grey-700:    #455A64;
$color-blue-grey-800:    #37474F;
$color-blue-grey-900:    #263238;

$color-black:            #000000;
$color-white:            #FFFFFF;
