@import "colors";

.entities-list {
  .row {
    padding: 0 7.5px;

    & > div {
      padding: 0 7.5px;
      margin-bottom: 15px;

      &::before {
        content: "";
        display: block;
        padding-top: 100%;
        float: left;
      }

      & > div {
        height: 100%;
        border-radius: 10px;
        background-color: $color-grey-800;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.075);
      }
    }
  }
}

.empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;

  .icon {
    text-align: center;

    .mdi {
      width: 50%;
      max-height: 40vh;
      color: $color-grey-300;
    }
  }

  .message {
    margin-top: 30px;
    text-align: center;
    color: $color-grey-300;
  }
}
