@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 900;
  font-display: fallback;
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Black.eot');
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Black.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSans/woff2/IRANSansWeb_Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSans/woff/IRANSansWeb_Black.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSans/ttf/IRANSansWeb_Black.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: bold;
  font-display: fallback;
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Bold.eot');
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSans/woff2/IRANSansWeb_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSans/woff/IRANSansWeb_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSans/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 500;
  font-display: fallback;
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Medium.eot');
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSans/woff2/IRANSansWeb_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSans/woff/IRANSansWeb_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSans/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 300;
  font-display: fallback;
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Light.eot');
	src: url('./fonts/IRANSans/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSans/woff2/IRANSansWeb_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSans/woff/IRANSansWeb_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSans/ttf/IRANSansWeb_Light.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 200;
  font-display: fallback;
	src: url('./fonts/IRANSans/eot/IRANSansWeb_UltraLight.eot');
	src: url('./fonts/IRANSans/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSans/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSans/woff/IRANSansWeb_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSans/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
  font-display: fallback;
	src: url('./fonts/IRANSans/eot/IRANSansWeb.eot');
	src: url('./fonts/IRANSans/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSans/woff2/IRANSansWeb.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSans/woff/IRANSansWeb.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSans/ttf/IRANSansWeb.ttf') format('truetype');
}

@font-face {
	font-family: IRANSansFaNum;
	font-style: normal;
	font-weight: 900;
  font-display: fallback;
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Black.eot');
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Black.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSansFaNum/woff2/IRANSansWeb(FaNum)_Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSansFaNum/woff/IRANSansWeb(FaNum)_Black.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSansFaNum/ttf/IRANSansWeb(FaNum)_Black.ttf') format('truetype');
}
@font-face {
	font-family: IRANSansFaNum;
	font-style: normal;
	font-weight: bold;
  font-display: fallback;
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Bold.eot');
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSansFaNum/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSansFaNum/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSansFaNum/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}
@font-face {
	font-family: IRANSansFaNum;
	font-style: normal;
	font-weight: 500;
  font-display: fallback;
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Medium.eot');
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSansFaNum/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSansFaNum/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSansFaNum/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
}
@font-face {
	font-family: IRANSansFaNum;
	font-style: normal;
	font-weight: 300;
  font-display: fallback;
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Light.eot');
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSansFaNum/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSansFaNum/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSansFaNum/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
}
@font-face {
	font-family: IRANSansFaNum;
	font-style: normal;
	font-weight: 200;
  font-display: fallback;
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_UltraLight.eot');
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSansFaNum/woff2/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSansFaNum/woff/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSansFaNum/ttf/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
}
@font-face {
	font-family: IRANSansFaNum;
	font-style: normal;
	font-weight: normal;
  font-display: fallback;
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum).eot');
	src: url('./fonts/IRANSansFaNum/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./fonts/IRANSansFaNum/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./fonts/IRANSansFaNum/woff/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./fonts/IRANSansFaNum/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}
