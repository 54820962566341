@import "./colors";

.top-navbar {
  height: 55px;
  position: relative;
  background-color: #292929;

  .top-navbar-flex {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    padding: 0 5px;

    .right-menu {
      width: 55px;
      flex-grow: 0;
      flex-basis: auto;
      line-height: 0;
    }

    .title {
      flex-grow: 3;
      color: $color-grey-300;
      font-weight: bold;

      span {
        font-size: 16px;
      }
    }

    .left-menu {
      text-align: left;
      line-height: 0;
    }

    .mdi {
      color: $color-grey-300;
      width: 42px;
      padding: 8px;
      border-radius: 100%;
      background-color: transparent;
      transition: background-color 0.5s;

      &:active {
        background-color: $color-grey-800;
        transition: background-color 0s;
      }

      &.mdiBellRingOutline {
        color: $color-yellow-700;
        transform-origin: 50% 8px;
        animation: bell-ring 5s linear 2s infinite;
      }
    }
  }
}

@keyframes bell-ring {
  0%,
  4%,
  8%,
  12% {
    transform: rotateZ(10deg);
  }

  2%,
  6%,
  10%,
  14%,
  18% {
    transform: rotateZ(-10deg);
  }

  16%,
  20% {
    transform: rotateZ(0deg);
  }
}
