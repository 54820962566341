@import "colors";

.WeatherSummary {
  min-width: 70px;
  margin: 8px 0 10px 0;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;

  .am-flexbox-item {
    flex: none;

    &:first-child {
      line-height: 0;
    }
  }

  &.day {
    background-image: linear-gradient(
      -45deg,
      $color-light-blue-500,
      $color-light-blue-900
    );
  }

  &.night {
    background-image: linear-gradient(
      -45deg,
      $color-blue-500,
      $color-indigo-800
    );
  }

  .WeatherIcon {
    width: 50px;
    color: $color-white;

    &.mdiWeatherNight,
    &.mdiAlertCircleOutline,
    &.mdiWeatherPartlyCloudy,
    &.mdiWeatherSunny {
      color: $color-yellow-600;
    }
  }

  .WeatherIconBg {
    position: absolute;
    right: -10%;
    bottom: -10%;
    height: 100%;
    color: rgba(255, 255, 255, 0.1);
    transform: scale(1.5);
  }

  .Content {
    color: $color-white;
    text-align: center;
    font-weight: bold;
    padding: 0 15px;

    .Temperature {
      direction: ltr;
      font-size: 18px;

      .UnitIcon {
        width: 16px;
      }
    }

    .Label {
      margin-top: 8px;
      font-size: 16px;
    }
  }
}
