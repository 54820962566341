@import "colors";

.main-page {
  position: relative;
  overflow: hidden;

  .main-content-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .main-content {
    position: relative;
    height: 100vh;
    transition: transform 0.25s ease-in-out 0s;
  }

  &.menu-open {
    .main-content-overlay {
      z-index: 100;
    }

    .main-content {
      transform: scale(0.5) translateX(-75%);
    }
  }
}
