@import "./colors";

.persons {
  .cards-list {
    .no-message {
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 6px;
      color: $color-grey-300;
      background-color: $color-grey-800;
    }

    .card {
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 6px;
      background-color: $color-grey-800;

      .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: $color-grey-300;
      }
    }
  }
}
