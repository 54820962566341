@import "colors";

.main-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  color: $color-grey-200;
  background-color: $color-grey-800;

  & > .am-flexbox {
    height: 100%;
  }

  .top-navbar {
    background-color: transparent;

    .top-navbar-flex {
      .mdi {
        color: inherit;

        &:active {
          background-color: $color-grey-700;
        }
      }
    }
  }

  .menu-content {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        padding: 15px 5vw;
        transition: background-color 0.25s;

        .mdi {
          width: 28px;
          vertical-align: middle;
        }

        span {
          padding-right: 10px;
          vertical-align: -1px;
          font-weight: bold;
          font-size: 1rem;
        }

        &:active {
          background-color: $color-grey-700;
          transition: background-color 0s;
        }

        &.users {
          span {
            vertical-align: -2px;
          }
        }
      }
    }
  }

  .menu-footer {
    .top-navbar {
      .title {
        flex-grow: 0;
      }

      .logout-button {
        display: inline-block;
        border-radius: 10px;
        transition: background-color 0.25s;

        .mdi {
          vertical-align: middle;
          background-color: transparent !important;
        }

        strong {
          vertical-align: middle;
          padding: 8px 0 8px 8px;
        }

        &:active {
          background-color: $color-grey-700;
          transition: background-color 0s;
        }
      }
    }
  }
}
