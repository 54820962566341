@import "colors";

.rooms-list {
  .row {
    padding: 0 7.5px;

    & > div {
      padding: 0 7.5px;
      margin-bottom: 15px;

      &::before {
        content: "";
        display: block;
        padding-top: 100%;
        float: left;
      }

      .room {
        height: 100%;
        border-radius: 10px;
        background-color: $color-grey-800;
        transition: background-color 0.25s;
        box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.075);

        &:active {
          background-color: $color-grey-700;
          transition: background-color 0s;
        }

        .content,
        .title {
          flex-grow: 3;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .mdi {
            width: 50%;
            color: $color-grey-300;
          }
        }

        .title {
          flex-grow: 1;
          justify-content: start;
          color: $color-grey-300; 
        }
      }
    }
  }
}
