@import "animations";
@import "colors";

.activity {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  background-color: $color-grey-900;

  animation-name: push;
  animation-iteration-count: 1;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 1ms;

  &.no-animation {
    animation: none;
  }

  &.closing {
    animation-name: pull;
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .activity-layout {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > div:last-child {
      flex-grow: 1;
      overflow-y: auto;
      padding: 15px 15px 0 15px;
    }
  }
}
