@import "colors";

.welcome-text {
  line-height: 17px;
  padding: 14px 10px;
  border-radius: 10px;
  color: $color-grey-400;
  background-color: rgba(255, 255, 255, 0.075);
  transition: all 0.25s ease-in-out 0s, border-radius 0s;

  &.active {
    color: $color-yellow-700;
    border-radius: 0;
    border-right: 5px solid #ffc32fdb;
    background-color: rgba($color-yellow-700, 0.075);
  }
}
