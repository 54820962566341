@import "colors";

/* Modify antd-mobile */
.am-flexbox {
  text-align: right;

  &.am-flexbox-dir-row .am-flexbox-item {
    margin-left: 0;
  }
}

.flex-grow-3 {
  flex-grow: 3 !important;
}

.flexbox-items-grow-0 > .am-flexbox-item {
  flex-grow: 0;

  &.flexbox-grow {
    flex-grow: 1;
  }
}

.am-modal-wrap {
  .am-modal {
    .am-modal-content {
      background-color: $color-grey-800;

      .am-modal-title {
        color: $color-grey-400;
      }

      .am-modal-alert-content {
        color: $color-grey-400;
        white-space: pre-line;
      }

      .am-modal-button-group-h {
        direction: ltr;

        &::before {
          background-color: $color-grey-700 !important;
        }
      }

      .am-modal-button {
        font-size: 16px;
        color: $color-blue-300;
        border-color: $color-grey-700;

        &::before {
          border-color: $color-grey-700 !important;
          background-color: $color-grey-700 !important;
        }

        &.am-modal-button-active {
          background-color: $color-grey-700;
        }
      }
    }
  }
}

.am-toast {
  opacity: 0;
  transition: opacity 0.5s ease-in-out 0s;

  &.am-toast-mask {
    background-color: rgba($color-grey-900, 0.75);
  }

  &.visible {
    opacity: 1;
  }

  & > span {
    max-width: 80%;
  }

  .am-toast-text-info,
  .am-toast-text > div {
    white-space: pre-line;
  }
}

.am-list-item {
  padding-left: 0 !important;
  background-color: transparent;

  .am-list-line {
    padding-right: 0;
  }
}

.am-input-item {
  &.am-input-text-left {
    .am-input-control {
      text-align: left;
      direction: ltr;
    }
  }

  .am-input-label {
    text-align: right;
    margin-right: 0;
    margin-left: 5px;
    color: $color-grey-400;
    transition: all 0.3s ease;
  }

  .am-input-control input {
    color: $color-grey-400;
    transition: all 0.3s ease;
  }

  .am-list-line::after {
    background-color: $color-grey-400 !important;
    background-image: linear-gradient($color-amber-600, $color-amber-600);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-position: center;
    transition: all 0.1s ease;
  }

  &.am-input-focus {
    .am-input-label,
    .am-input-control input {
      color: $color-grey-200 !important;
    }

    .am-list-line::after {
      background-size: 100% 100%;
    }
  }
}
