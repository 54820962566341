@import "./colors";

.notifications {
  .cards-list {
    .no-message {
      margin-bottom: 15px;
      padding: 15px;
      border-radius: 6px;
      color: $color-grey-300;
      background-color: $color-grey-800;
    }

    .card {
      margin-bottom: 15px;
      border-radius: 6px;
      overflow: hidden;
      background-color: $color-grey-800;

      .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        padding: 18px 15px;
        color: $color-grey-200;
        background-color: rgba(#000, 0.15);
      }

      .text {
        font-size: 14px;
        line-height: 26px;
        color: $color-grey-300;
        padding: 15px;
      }

      .action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: rgba(#000, 0.1);

        div {
          font-weight: bold;
          padding: 15px;
          color: $color-red-A200;
        }
      }

      &.danger {
        background-color: $color-deep-orange-900;

        .title,
        .text,
        .action div {
          color: $color-grey-50;
        }
      }
    }
  }
}
